import { render, staticRenderFns } from "./VIB.vue?vue&type=template&id=3f9711f4&scoped=true&"
import script from "./VIB.vue?vue&type=script&lang=ts&"
export * from "./VIB.vue?vue&type=script&lang=ts&"
import style0 from "./VIB.vue?vue&type=style&index=0&id=3f9711f4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f9711f4",
  null
  
)

export default component.exports