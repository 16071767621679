import {ExplorationRequest, StringKeyValueType} from '@/components/Checkout/ExplorationV2/models/Types';
import CheckoutRequestApi from '@/components/Rest/requests/CheckoutRequestApi';
import ExporoVue from '@/components/ExporoVue';

// @TODO id deprecated?
/* tslint:disable */
class ExplorationService extends ExporoVue {

    static MIN_VALUE = 44;

    public employmentStatusOptions: StringKeyValueType = {
        'employee': this.trans.get('checkout.employment_status.employmentStatusOptions.employee'),
        'pensioner': this.trans.get('checkout.employment_status.employmentStatusOptions.pensioner'),
        'privateer': this.trans.get('checkout.employment_status.employmentStatusOptions.privateer'),
        'public_official': this.trans.get('checkout.employment_status.employmentStatusOptions.public_official'),
        'self_employed': this.trans.get('checkout.employment_status.employmentStatusOptions.self_employed'),
        'student': this.trans.get('checkout.employment_status.employmentStatusOptions.student'),
    };

    private onValueChanged!: Function;

    public form: ExplorationRequest = {
        exploration: {
            employmentStatus: 'employee',
            job_0: '0;0',
            job_1: '0;0',
            job_2: '0;0',
            equity_0: '0;0',
            equity_1: '0;0',
            equity_2: '0;0',
            bonds_0: '0;0',
            bonds_1: '0;0',
            bonds_2: '0;0',
            subordinated_0: '0;0',
            subordinated_1: '0;0',
            subordinated_2: '0;0',
            funds_0: '0;0',
            funds_1: '0;0',
            funds_2: '0;0',
            stocks_0: '0;0',
            stocks_1: '0;0',
            stocks_2: '0;0',
            explorationWarning: this.trans.get('checkout.employment_status.exploration_warning'),
            value: 0
        },
        equity: '0',
        bonds: '0',
        subordinated: '0',
        funds: '0',
        stocks: '0',
        exploration_incomplete_accepted: false
    };

    public changeFormByKey(name: string, value: string) {
        this.form[name] = value;
        this.updateValue();
    }

    public updateValue() {
        let value = 0;
        for (let formKey in this.form.exploration) {
            if ('value' !== formKey) {
                let formValue = this.form.exploration[formKey];

                if ('string' === typeof formValue) {
                    formValue = formValue.split(';')[0];
                    formValue = formValue.replace(',', '.');
                }

                if (!isNaN(+formValue)) {
                    value += +formValue;
                }
            }
        }

        this.form.exploration.value = value;

        if (this.onValueChanged) {
            this.onValueChanged();
        }
    }

    public setValueChangedCallback(callback: Function) {
        this.onValueChanged = callback;
    }

    createExplorationPreview(callback: (success: boolean) => void, checkoutRequestApi: CheckoutRequestApi) {
        ['equity', 'bonds', 'subordinated', 'funds', 'stocks'].forEach((name) => {
            let value = this.form[name];
            this.form[name] = '0' === value ? 'Nein' : 'Ja';
        });

        checkoutRequestApi.createExplorationPreview(this.form, {
            onSuccess: () => callback(true),
            onFailure: () => callback(false)
        });
    }

    public selfEmployedOccupations: StringKeyValueType = {};

    public jobOptions: StringKeyValueType = {};

    public jobOptionsPast: StringKeyValueType = {};

    public educationOptions: StringKeyValueType = {};

    constructor() {
        super();

        this.selfEmployedOccupations['-'] = '0;0',
            this.selfEmployedOccupations[this.trans.get('checkout.employment_status.branche.self_employed_occupations.landwirtschaft')] = '0;' + this.trans.get('checkout.employment_status.branche.self_employed_occupations.landwirtschaft'),
            this.selfEmployedOccupations[this.trans.get('checkout.employment_status.branche.self_employed_occupations.bergbau')] = '0;' + this.trans.get('checkout.employment_status.branche.self_employed_occupations.bergbau'),
            this.selfEmployedOccupations[this.trans.get('checkout.employment_status.branche.self_employed_occupations.glasswaren')] = '0;' + this.trans.get('checkout.employment_status.branche.self_employed_occupations.glasswaren'),
            this.selfEmployedOccupations[this.trans.get('checkout.employment_status.branche.self_employed_occupations.kraftfahrzeug_branche')] = '0;' + this.trans.get('checkout.employment_status.branche.self_employed_occupations.kraftfahrzeug_branche'),
            this.selfEmployedOccupations[this.trans.get('checkout.employment_status.branche.self_employed_occupations.energieversorgung')] = '0;' + this.trans.get('checkout.employment_status.branche.self_employed_occupations.energieversorgung'),
            this.selfEmployedOccupations[this.trans.get('checkout.employment_status.branche.self_employed_occupations.wasserversorgung_entsorgung')] = '0;' + this.trans.get('checkout.employment_status.branche.self_employed_occupations.wasserversorgung_entsorgung'),
            this.selfEmployedOccupations[this.trans.get('checkout.employment_status.branche.self_employed_occupations.hochbau_bau_von_gebaeuden')] = '0;' + this.trans.get('checkout.employment_status.branche.self_employed_occupations.hochbau_bau_von_gebaeuden'),
            this.selfEmployedOccupations[this.trans.get('checkout.employment_status.branche.self_employed_occupations.handel_mit_kfz_u_service')] = '0;' + this.trans.get('checkout.employment_status.branche.self_employed_occupations.handel_mit_kfz_u_service'),
            this.selfEmployedOccupations[this.trans.get('checkout.employment_status.branche.self_employed_occupations.einzelhandel')] = '0;' + this.trans.get('checkout.employment_status.branche.self_employed_occupations.einzelhandel'),
            this.selfEmployedOccupations[this.trans.get('checkout.employment_status.branche.self_employed_occupations.verkehrsdienstleistungen')] = '0;' + this.trans.get('checkout.employment_status.branche.self_employed_occupations.verkehrsdienstleistungen'),
            this.selfEmployedOccupations[this.trans.get('checkout.employment_status.branche.self_employed_occupations.gastronomie')] = '0;' + this.trans.get('checkout.employment_status.branche.self_employed_occupations.gastronomie'),
            this.selfEmployedOccupations[this.trans.get('checkout.employment_status.branche.self_employed_occupations.informationsdienstleistungen')] = '0;' + this.trans.get('checkout.employment_status.branche.self_employed_occupations.informationsdienstleistungen'),
            this.selfEmployedOccupations[this.trans.get('checkout.employment_status.branche.self_employed_occupations.grundstuecks_u_wohungswesen')] = '0;' + this.trans.get('checkout.employment_status.branche.self_employed_occupations.grundstuecks_u_wohungswesen'),
            this.selfEmployedOccupations[this.trans.get('checkout.employment_status.branche.self_employed_occupations.freiberuf_wissenschaftl_taetigkeiten')] = '0;' + this.trans.get('checkout.employment_status.branche.self_employed_occupations.freiberuf_wissenschaftl_taetigkeiten'),
            this.selfEmployedOccupations[this.trans.get('checkout.employment_status.branche.self_employed_occupations.wirtschaftliche_dienstleistungen')] = '0;' + this.trans.get('checkout.employment_status.branche.self_employed_occupations.wirtschaftliche_dienstleistungen'),
            this.selfEmployedOccupations[this.trans.get('checkout.employment_status.branche.self_employed_occupations.oeffentliche_verwaltung')] = '0;' + this.trans.get('checkout.employment_status.branche.self_employed_occupations.oeffentliche_verwaltung'),
            this.selfEmployedOccupations[this.trans.get('checkout.employment_status.branche.self_employed_occupations.erziehung_u_unterricht')] = '0;' + this.trans.get('checkout.employment_status.branche.self_employed_occupations.erziehung_u_unterricht'),
            this.selfEmployedOccupations[this.trans.get('checkout.employment_status.branche.self_employed_occupations.gesundheitswesen')] = '0;' + this.trans.get('checkout.employment_status.branche.self_employed_occupations.gesundheitswesen'),
            this.selfEmployedOccupations[this.trans.get('checkout.employment_status.branche.self_employed_occupations.kuenstlerische_taetigkeiten')] = '0;' + this.trans.get('checkout.employment_status.branche.self_employed_occupations.kuenstlerische_taetigkeiten'),
            this.selfEmployedOccupations[this.trans.get('checkout.employment_status.branche.self_employed_occupations.dienstleist_sport_unterhaltung')] = '0;' + this.trans.get('checkout.employment_status.branche.self_employed_occupations.dienstleist_sport_unterhaltung');

        this.jobOptions['-'] = '0;0',
            this.jobOptions[this.trans.get('checkout.employment_status.job_education.current_job.data.asset_manager')] = '10;' + this.trans.get('checkout.employment_status.job_education.current_job.data.asset_manager'),
            this.jobOptions[this.trans.get('checkout.employment_status.job_education.current_job.data.finanzberater')] = '10;' + this.trans.get('checkout.employment_status.job_education.current_job.data.finanzberater'),
            this.jobOptions[this.trans.get('checkout.employment_status.job_education.current_job.data.finanzmakler')] = '10;' + this.trans.get('checkout.employment_status.job_education.current_job.data.finanzmakler'),
            this.jobOptions[this.trans.get('checkout.employment_status.job_education.current_job.data.finanzvermittler')] = '10;' + this.trans.get('checkout.employment_status.job_education.current_job.data.finanzvermittler'),
            this.jobOptions[this.trans.get('checkout.employment_status.job_education.current_job.data.investment_banker')] = '10;' + this.trans.get('checkout.employment_status.job_education.current_job.data.investment_banker'),
            this.jobOptions[this.trans.get('checkout.employment_status.job_education.current_job.data.wirtschaftspruefer')] = '10;' + this.trans.get('checkout.employment_status.job_education.current_job.data.wirtschaftspruefer'),
            this.jobOptions[this.trans.get('checkout.employment_status.job_education.current_job.data.bankangestellter')] = '5;' + this.trans.get('checkout.employment_status.job_education.current_job.data.bankangestellter'),
            this.jobOptions[this.trans.get('checkout.employment_status.job_education.current_job.data.leitende_funktion_controlling')] = '5;' + this.trans.get('checkout.employment_status.job_education.current_job.data.leitende_funktion_controlling'),
            this.jobOptions[this.trans.get('checkout.employment_status.job_education.current_job.data.leitende_funktion_finanzabteilung')] = '5;' + this.trans.get('checkout.employment_status.job_education.current_job.data.leitende_funktion_finanzabteilung'),
            this.jobOptions[this.trans.get('checkout.employment_status.job_education.current_job.data.steuerberater')] = '10;' + this.trans.get('checkout.employment_status.job_education.current_job.data.steuerberater'),
            this.jobOptions[this.trans.get('checkout.employment_status.job_education.current_job.data.kfm_angestellter')] = '5;' + this.trans.get('checkout.employment_status.job_education.current_job.data.kfm_angestellter'),
            this.jobOptions[this.trans.get('checkout.employment_status.job_education.current_job.data.handwerker')] = '0;' + this.trans.get('checkout.employment_status.job_education.current_job.data.handwerker'),
            this.jobOptions[this.trans.get('checkout.employment_status.job_education.current_job.data.rentner_pensionaer')] = '0;' + this.trans.get('checkout.employment_status.job_education.current_job.data.rentner_pensionaer'),
            this.jobOptions[this.trans.get('checkout.employment_status.job_education.current_job.data.student_auszubildender')] = '0;' + this.trans.get('checkout.employment_status.job_education.current_job.data.student_auszubildender'),
            this.jobOptions[this.trans.get('checkout.employment_status.job_education.current_job.data.sonstige')] = '0';

        this.jobOptionsPast['-'] = '0;0',
            this.jobOptionsPast[this.trans.get('checkout.employment_status.job_education.past_job.data.asset_manager')] = '10;' +this.trans.get('checkout.employment_status.job_education.past_job.data.asset_manager'),
            this.jobOptionsPast[this.trans.get('checkout.employment_status.job_education.past_job.data.finanzberater')] = '10;' +this.trans.get('checkout.employment_status.job_education.past_job.data.finanzberater'),
            this.jobOptionsPast[this.trans.get('checkout.employment_status.job_education.past_job.data.finanzmakler')] = '10;' +this.trans.get('checkout.employment_status.job_education.past_job.data.finanzmakler'),
            this.jobOptionsPast[this.trans.get('checkout.employment_status.job_education.past_job.data.finanzvermittler')] = '10;' +this.trans.get('checkout.employment_status.job_education.past_job.data.finanzvermittler'),
            this.jobOptionsPast[this.trans.get('checkout.employment_status.job_education.past_job.data.investment_banker')] = '10;' +this.trans.get('checkout.employment_status.job_education.past_job.data.investment_banker'),
            this.jobOptionsPast[this.trans.get('checkout.employment_status.job_education.past_job.data.wirtschaftspruefer')] = '10;' +this.trans.get('checkout.employment_status.job_education.past_job.data.wirtschaftspruefer'),
            this.jobOptionsPast[this.trans.get('checkout.employment_status.job_education.past_job.data.bankangestellter')] = '5;' +this.trans.get('checkout.employment_status.job_education.past_job.data.bankangestellter'),
            this.jobOptionsPast[this.trans.get('checkout.employment_status.job_education.past_job.data.leitende_funktion_controlling')] = '5;' +this.trans.get('checkout.employment_status.job_education.past_job.data.leitende_funktion_controlling'),
            this.jobOptionsPast[this.trans.get('checkout.employment_status.job_education.past_job.data.leitende_funktion_finanzabteilung')] = '5;' +this.trans.get('checkout.employment_status.job_education.past_job.data.leitende_funktion_finanzabteilung'),
            this.jobOptionsPast[this.trans.get('checkout.employment_status.job_education.past_job.data.steuerberater')] = '5;' +this.trans.get('checkout.employment_status.job_education.past_job.data.steuerberater'),
            this.jobOptionsPast[this.trans.get('checkout.employment_status.job_education.past_job.data.kfm_angestellter')] = '5;' +this.trans.get('checkout.employment_status.job_education.past_job.data.kfm_angestellter'),
            this.jobOptionsPast[this.trans.get('checkout.employment_status.job_education.past_job.data.handwerker')] = '0;' +this.trans.get('checkout.employment_status.job_education.past_job.data.handwerker'),
            this.jobOptionsPast[this.trans.get('checkout.employment_status.job_education.past_job.data.rentner_pensionaer')] = '0;' +this.trans.get('checkout.employment_status.job_education.past_job.data.rentner_pensionaer'),
            this.jobOptionsPast[this.trans.get('checkout.employment_status.job_education.past_job.data.student_auszubildender')] = '0;' +this.trans.get('checkout.employment_status.job_education.past_job.data.student_auszubildender'),
            this.jobOptionsPast[this.trans.get('checkout.employment_status.job_education.past_job.data.sonstige')] = '0',

        this.educationOptions['-'] = '0;0',
            this.educationOptions[this.trans.get('checkout.employment_status.job_education.educational_achievement.data.promotion_habilitation')] = '10;' + this.trans.get('checkout.employment_status.job_education.educational_achievement.data.promotion_habilitation'),
            this.educationOptions[this.trans.get('checkout.employment_status.job_education.educational_achievement.data.hochschulabschluss')] = '8;' + this.trans.get('checkout.employment_status.job_education.educational_achievement.data.hochschulabschluss'),
            this.educationOptions[this.trans.get('checkout.employment_status.job_education.educational_achievement.data.kaufmaennische_berufsausbildung')] = '5;' + this.trans.get('checkout.employment_status.job_education.educational_achievement.data.kaufmaennische_berufsausbildung'),
            this.educationOptions[this.trans.get('checkout.employment_status.job_education.educational_achievement.data.fachhochschul_hochschulreife')] = '3;' + this.trans.get('checkout.employment_status.job_education.educational_achievement.data.fachhochschul_hochschulreife'),
            this.educationOptions[this.trans.get('checkout.employment_status.job_education.educational_achievement.data.sonstige_berufsausbildung')] = '1;' + this.trans.get('checkout.employment_status.job_education.educational_achievement.data.sonstige_berufsausbildung'),
            this.educationOptions[this.trans.get('checkout.employment_status.job_education.educational_achievement.data.haupt_volks_realschulabschluss')] = '0;' + this.trans.get('checkout.employment_status.job_education.educational_achievement.data.haupt_volks_realschulabschluss'),
            this.educationOptions[this.trans.get('checkout.employment_status.job_education.educational_achievement.data.keiner_der_genannten_abschluesse')] = '0;' + this.trans.get('checkout.employment_status.job_education.educational_achievement.data.keiner_der_genannten_abschluesse');

    }
}

export default ExplorationService;
