



































































































































    import ExporoVue from '../../ExporoVue';
    import RequestCallback from '../../Rest/RequestCallback';
    import RestError from '../../Rest/models/RestError';
    import {AxiosResponse} from 'axios';
    import Events from '../../../events';
    import {Component, Prop, Watch} from 'vue-property-decorator';
    import InvestService from '../services/InvestService';
    import ForceEmailCheckbox from '@/components/Checkout/ForceEmailCheckbox/ForceEmailCheckbox.vue';
    import Error from '@/components/Checkout/Validator/Error.vue';
    import CheckoutValidator from '../Validator/CheckoutValidator';
    import DateFormatHelper from '../../Helper/DateFormatHelper';
    import Checkbox from '@/components/Checkbox/Checkbox.vue';
    import Input from '@/components/Input/Input.vue';
    import InvestmentRequestPart from '../models/InvestmentRequestPart';
    import StoreInvestmentRequestService from '../services/StoreInvestmentRequestService';
    import LocalStorage from '@/components/LocalStorage/LocalStorage';

    @Component({
        components: {
            ForceEmailCheckbox,
            Error,
            Checkbox,
            Input
        }
    })
    class VIB extends ExporoVue implements RequestCallback, InvestmentRequestPart {

        public TITLE = this.privatePlacement
            ? 'Angebotsunterlagen'
            : 'Vermögensanlagen-Informationsblatt';

        private payload = {
            legal_obligation: false,
            vib_file: false,
            vib: {
                signature: ''
            }
        };

        @Prop()
        private investService!: InvestService;
        @Prop()
        private checkoutValidator!: CheckoutValidator;
        @Prop()
        private shouldHide!: boolean;
        @Prop()
        private switchStep!: () => void;
        @Prop()
        private user!: models.User;
        @Prop()
        private investmentProduct!: models.InvestmentProduct;
        @Prop()
        private country!: string;
        private investor: any = null;
        @Prop()
        private step!: number;
        private signature: string = '';

        @Prop()
        private isPrivatePlacement!: boolean;

      @Prop()
      private isPreFinancing!: boolean;
//
        private userDate = DateFormatHelper.getFormattedDateFromString(this.user.dob);
        private investorDate = '';

        private shouldSendLegals: boolean = false;

        @Prop()
        private storeInvestmentRequestService!: StoreInvestmentRequestService;

        constructor() {
            super();
            this.updateInvestorData();

            Events.$on(InvestService.investChangedChannel, () => {
                            this.updateInvestorData();
                        });
            this.storeInvestmentRequestService.registerRequestPart(this);
        }

        mounted() {
          if (this.isPrivatePlacement || this.isPreFinancing) {
            this.TITLE = 'Gesetzliche Pflichtangaben';
            this.shouldSendLegals = true;
          }

          if (LocalStorage.getBoolean('userEditMode', false)) {
            const vibData = LocalStorage.get('vibData', null);

            if (vibData) {
              this.payload = JSON.parse(vibData);
              this.signature = this.payload.vib.signature;
            }

            LocalStorage.addBoolean('userEditMode', false);
          }
        }

        updateInvestorData() {
                    this.investor = this.investService.getInvestor();
                    if (null !== this.investor) {
                        this.investorDate = DateFormatHelper.getFormattedDateFromString(this.investor.dob);
                    }
                    this.$forceUpdate();
        }

        saveSignature() {
            // this.investService.updateFomData('vib[signature]', this.signature);
            this.payload.vib.signature = this.signature;
        }

        openUserEdit(e) {
          e.preventDefault();
          LocalStorage.add('vibData', JSON.stringify(this.getRequestPart()));
          LocalStorage.addBoolean('userEditMode', true);
          // Events.$emit('editUserData');
        }

        onFailure(error: RestError): void {
        }

        onSuccess(response: AxiosResponse): void {
        }

        getRequestPart(): object {

            return this.payload;
        }

        get privatePlacement(): boolean {
            return this.isPrivatePlacement;
        }
    }

    export default VIB;
