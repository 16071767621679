


































































import {Component, Vue} from 'vue-property-decorator';
import AuthService from './services/AuthService';
import RequestCallback from '../Rest/RequestCallback';
import {AxiosResponse} from 'axios';
import Credentials from './models/Credentials';
import RestApiService from '../Rest/services/RestApiService';
import ExporoVue from '../ExporoVue';
import Checkbox from '@/components/Checkbox/Checkbox.vue';
import Tracking from '@/components/GoogleTagManager/services/Tracking';
import RestError from '../Rest/models/RestError';
import Input from '@/components/Input/Input.vue';
import ExporoConfig, {LegalText} from '@/ExporoConfig';
import URLHelper from '@/components/Helper/URLHelper';
import CookieHelper from '@/components/Helper/CookieHelper';

@Component({
    components: {
        Checkbox,
        Input,
    },
    props: {
        openAuthModalByName: Function,
        modalNames: Object,
    },
})
export default class Register extends ExporoVue implements RequestCallback {

    public input: any;
    public $props: any;
    private authService: AuthService;
    private successText: any;
    private errorText: any = '';
    private sendRegisterComplete: boolean = false;
    private legalText: LegalText;

    constructor() {
        super();
        this.authService = new AuthService(new RestApiService(this));
        this.legalText = ExporoConfig.LEGAL_TEXT;
    }

    data() {
        return {
            input: {
                email: '',
            },
        };
    }

    getCCTLD(): string {

        return URLHelper.getCCTLD().toLowerCase();
    }

    buildLinkByOrigin(slug: string): string {

        return `https://exporo.${URLHelper.getCCTLD().toLowerCase() + slug}`;
    }

    register() {

        const root: any = this.$refs.root;

        const checkboxes: any = root.querySelectorAll('input[type="checkbox"]');

        let count: number = 0;
        const countTarget: number = checkboxes.length;

        [...checkboxes].forEach((checkbox: any) => {
            if (!checkbox.checked) {
                checkbox.parentElement.querySelector('label').style.color = 'red';
            } else {
                checkbox.parentElement.querySelector('label').style.color = '#b1b1b1';
                count++;
            }
        });

        if (countTarget === count) {
            const recommendFrom = CookieHelper.getCookie('affiliated') || URLHelper.getQueryParam('a_aid') || '0';
            const a_aid_ref = CookieHelper.getCookie('a_aid_ref') || URLHelper.getQueryParam('a_aid_ref') || '0';

            this.authService.sendRegister(new Credentials(this.input.email, null), this, recommendFrom, a_aid_ref);
            Tracking.trackSubmit('registration form', false, undefined);
        }
    }


    openModalByName(name: string) {

        this.$props.openAuthModalByName(name);
    }

    closeModal(): void {
        this.sendRegisterComplete = false;
        this.getModalService().closeModal('register-modal');
    }

    onFailure(error: RestError): void {
        const message: any = error.errorMessage;
        this.errorText = message.email[0];
        Tracking.trackError('registration form', true, undefined);
    }


    onSuccess(response: AxiosResponse): void {
        if (void (0) !== response.data.userId) {
            this.successText = '';
            if (this.legalText.REGISTER_SUCCESS) {
                this.successText = this.legalText.REGISTER_SUCCESS;
            } else {
                this.successText = this.trans.get('auth.modal.success_text');
            }

            Tracking.trackAny({
                'appId': 'checkout_exporo_de',
                'eventCategory': 'mkt-userInteraction',
                'eventAction': 'register',
                'eventLabel': response.data.userId,
                'eventValue': 0,
                'eventNonInteraction': 'false',
                'event': 'gtmEvent',
            });

        } else {
            this.successText = response.data;
        }

        if (window.location.href.indexOf('accontis') > -1) {
            window.dataLayer = window.dataLayer || [];

            (window.dataLayer as any).push({
                'event': 'new_register'
            });
        }

        this.sendRegisterComplete = true;
    }
}
