/* tslint:disable */

const fr = {
    auth: {
        activation: {
            affiliated_partner_id: 'Recommandé par (Facultatif)',
            error: 'Veuillez saisir un identifiant de parrain valide.',
            female: 'Mme.',
            first_name: 'Prénom',
            format_phone: 'Format: "+33 (0)1 23 45 67 89',
            format_mobile_phone: 'Format: "+33 (0)6 12 34 56 78',
            last_name: 'Nom',
            male: 'Monsieur',
            password: 'Mot de passe',
            password_lacks_complexity: 'Utilisez au moins 8 caractères dans votre mot de passe et une lettre majuscule, une lettre minuscule et un chiffre ou un caractère spécial.',
            phone: 'Numéro de téléphone',
            phone_error: 'Veuillez incrire un numéro de téléphone.',
            phone_mobile: 'Numéro de téléphone portable',
            phone_mobile_error: 'Veuillez incrire un numéro de téléphone portable.',
            repeat_password: 'Confirmer le mot de passe',
            save: 'Sauvegarder',
            title: 'Inscription complète',
            toast_error: 'Vous utilisez un lien d\'activation erroné.'
        },
        cockpitButton: 'Cockpit d\'investissement',
        loginButton: 'Connexion',
        logoutButton: 'Déconnexion',
        modal: {
            checkbox_exporo_ag: (href1: string, href2: string, href3: string) => `Par la présente, j'accepte de recevoir des informations sur les investissements, les mises à jour de projets et les informations relatives à la plateforme par le biais de la newsletter.  Je peux révoquer ce consentement volontaire <a href="${href1}" target="_blank">consent </a> à tout moment. De plus, je suis d'accord avec le <a href="${href2}" target="_blank">AGB</a> et la <a href="${href3}" target="_blank">Déclaration de confidentialité d'Exporo AG</a>.`,
            checkbox_exporo_investment_gmbh: (href1: string, href2: string, href3: string) => `J’accepte par la présente, de recevoir des bulletins d’information sur l’investissement en capital, sur les projets actuels ainsi que des informations générales en rapport avec Exporo. Je peux révoquer ce <a href="${href1}" target="_blank">consentement </a> à tout moment. Par ailleurs j’accepte les <a href="${href2}" target="_blank">CGU</a> ainsi que la <a href="${href3}" target="_blank">Déclaration de confidentialité d'EPH</a>.`,
            email_placeholder: 'Adresse e-mail',
            error_password: 'Assurez-vous d\'entrer les bonnes données de connexion.',
            password_forgot: 'Mot de passe oublié ?',
            password_placeholder: 'Mot de passe',
            register_checkbox_pre_hint: (CCTLD: string) => `En créant un compte sur Exporo.${CCTLD} j'accepte :`,
            register_checkbox_suf_hint_1: 'J\'accepte les termes des Cookies et de la publicité sur Internet.',
            register_checkbox_suf_hint_2: 'Les données personnelles continueront d\'être gérées par Exporo AG.',
            remember_me: 'Rester connecté sur cet appareil',
            submits: {
                login: 'Connexion',
                register: 'S\'inscrire'
            },
            success_text: `<h1>DERNIERE ETAPE</h1><p class=\"description\"> Merci de votre intérêt. Vous recevrez prochainement un email d'Exporo. Veuillez cliquer sur le lien dans l'email afin de compléter votre inscription. </p> </p>`,
            tabs: {
                login: 'Connexion',
                register: 'S\'inscrire'
            }
        },
        passwordChange: {
            current_password_placeholder: 'Mot de passe actuel',
            error_messages: {
                current_password_check_failed: 'Veuillez entrer votre mot de passe actuel.',
                current_password_required: 'Veuillez remplir le champ \'Mot de passe actuel\'.',
                new_password_required: 'Veuillez remplir le champ \'Nouveau mot de passe.\'',
                new_password_confirmation_failed: 'Le nouveau mot de passe ne correspond pas.',
                password_lacks_complexity: 'Dans votre mot de passe, utilisez au moins 8 caractères, une lettre majuscule, une lettre minuscule et un chiffre ou un caractère spécial.',
                password_used_before: 'Vous avez déjà utilisé le mot de passe saisi dans le passé. Veuillez entrer un nouveau mot de passe.'
            },
            new_password_hint: `<p> Ne pas utiliser un mot de passe que vous avez déjà utilisé dans le passé. <br> Votre mot de passe doit être au minimum de 8 caractères et doit contenir :</p><ul><li>une majuscule</li><li><b>et </b>une minuscule</li><li><b>et </b>un chiffre <b>ou</b>un caractère spécial</li></ul><p>Eviter les mot de passe faciles à deviner comme les noms et données des membres de famille (dates de naissances) ou d'adresse.</p>`,
            new_password_placeholder: 'Nouveau mot de passe',
            repeat_password_placeholder: 'Confirmer le nouveau mot de passe',
            submit: 'Modifier le mot de passe',
            title: 'Modifier le mot de passe'
        },
        passwordForget: {
            login: 'Se connecter ?',
            submit: 'Reinitialiser le mot de passe',
            title: 'Reinitialiser le mot de passe'
        },
        passwordReset: {
            new_password_hint: `<p>N'utilisez pas un mot de passe que vous avez déjà utilisé dans le passé.<br>Votre mot de passe doit comporter au moins 8 caractères et contenir les caractères suivants : </p> <ul> <li>une majuscule</li> <li><b>und</b>une minuscule</li><li><b>und</b> un chiffre <b>ou</b> un caractère spécial</li> </ul> <p> Évitez d'intégrer des éléments comme le nom d'un membre de la famille ou des données de naissance ou d'adresse. </p>`,
            password_hint: `<p> Ne pas utiliser un mot de passe que vous avez déjà utilisé dans le passé. <br> Votre mot de passe doit être au minimum de 8 caractères et doit contenir :</p><ul><li>une majuscule</li><li><b>et </b>une minuscule</li><li><b>et </b>un chiffre <b>ou</b>un caractère spécial</li></ul><p>Evitez les mot de passe faciles à deviner comme les noms et données des membres de famille (dates de naissances) ou adresse.</p>`,
            password_label: 'Mot de passe',
            repeat_password_label: 'Confirmer le mot de passe',
            submit: 'Confirmer le mot de passe',
            success: 'Mot de passe modifié avec succès',
            title: 'Définir le mot de passe'
        },
        submit_feedback: {
            label_submit: 'Envoyer le rapport',
            subtitle: 'Un rapport avec toutes les données nécessaires nous sera envoyé.',
            success_message: 'Nous avons reçu votre rapport, merci.',
            title: 'Signalez-nous le problème rencontré'
        }
    },
    basic: {
        no: 'Non',
        yes: 'Oui',
        day: 'Jour',
        month: 'Mois',
        year: 'Année'
    },
    checkout: {
        action_code: {
            error_message: 'Vos contributions n\'ont pas pu être évaluées. Veuillez réessayer.',
            error_messages: {
                'action_code_is_not_coupon': `Ce coupon n'est plus valable. Si vous avez des questions, veuillez nous contacter au <a href=\'tel:+33(0)179974037\'>+33(0)179974037</a> ou par email à : <a href=\'mailto:support@exporo.com\'>support@exporo.com</a>.`,
                'action_code_max_per_user': 'Vous avez déjà utilisé ce code promotionnel.',
                action_code_min_investsum: (value: string) => `Ce code est valable pour un investissement minimum de ${value} EUR.`,
                'action_code_not_expired': 'Le code de promotion n\'est plus valable.',
                'action_code_not_found': 'Le code n\'existe pas.',
                'action_code_only_first_signing': 'Le code de promotion n\'est valable que pour le premier investissement.',
                'action_code_only_follow_up_signing': 'Le code de promotion n\'est valable que pour les investissements ultérieurs.',
                'action_code_only_project': 'Le code ne peut pas être appliqué à ce projet.',
                'action_code_product_type': 'Le code ne peut pas être appliqué à ce projet.'
            },
            iban_alert: 'Un IBAN est requis pour la validation du coupon :',
            iban_input: 'IBAN pour les coupons',
            label: 'Entrer le coupon',
            not_reuse_iban: 'Vous pouvez utiliser un IBAN différent pour le coupon',
            placeholder: 'Coupon',
            reuse_iban: 'Utiliser le même IBAN',
            title: 'Votre coupon'
        },
        bond_store_success: {
            description: '<p> Afin de respecter les exigences légales en matière de lutte contre le blanchiment d\'argent, nous sommes tenus de vérifier votre identité. Ce processus n\'a lieu que lorsque vous faites votre premier investissement chez Exporo.</p>.\n<p>Pour cela, nous travaillons en collaboration avec IDnow, ce qui vous permet de vous identifier en vidéo. Ce service est simple, sécurisé et gratuit pour vous.</p>',
            headline: 'INVESTISSEMENT',
            host_button: 'Vers mon cockpit d\'investissement',
            ident_button: 'M\'identifier',
            ident_content: 'Afin de respecter les exigences légales en matière de lutte contre le blanchiment d\'argent, nous sommes tenus de vérifier votre identité. Ce processus n\'a lieu que lorsque vous faites votre premier investissement dans une obligation chez Exporo et est simple, sûr et gratuit pour vous.',
            ident_problem: 'Vous avez des soucis pour vous connecter ? Contactez-nous par e-mail à investment@exporo.fr ou du lundi au vendredi de 10h à 17h au 01 81 22 41 68.',
            ident_suggestion: 'Conseil : Veuillez garder votre pièce d\'identité à portée de main.',
            salutation_female: 'Chère Madame',
            salutation_male: 'Cher Monsieur',
            show_projects: 'Voir plus de projets',
            subline: (title: string, name: string) => `<p class=\"subline\">${title} ${name}, Félicitations. <br> Vous avez soumis votre offre avec succès!</p>`,
            suggestion: 'Conseil : Veuillez garder votre pièce d\'identité à portée de main.'
        },
        coupon: {
            confirmation: 'Confirmer',
            label: 'Investissement additionnel :'
        },
        employment_status: {
            branche: {
                self_employed_occupations: {
                    bergbau: 'Industrie minière',
                    dienstleist_sport_unterhaltung: 'Services. - Sports, divertissements',
                    einzelhandel: 'Commerce de détail',
                    energieversorgung: 'Approvisionnement d\'energie',
                    erziehung_u_unterricht: 'Education/Enseignement',
                    freiberuf_wissenschaftl_taetigkeiten: 'Activités scientifiques en freelance',
                    gastronomie: 'Gastronomie',
                    gesundheitswesen: 'Secteur de la santé',
                    glasswaren: 'Verrerie',
                    grundstuecks_u_wohungswesen: 'Immobilier et habitat',
                    handel_mit_kfz_u_service: 'Commerce de voitures et services',
                    hochbau_bau_von_gebaeuden: 'Secteur du bâtiment',
                    informationsdienstleistungen: 'Services de renseignements',
                    kraftfahrzeug_branche: 'Industrie automobile',
                    kuenstlerische_taetigkeiten: 'Activités artistiques',
                    landwirtschaft: 'Agriculture',
                    oeffentliche_verwaltung: 'Administration publique',
                    verkehrsdienstleistungen: 'Services des transports',
                    wasserversorgung_entsorgung: 'Approvisionnement en eau, évacuation des déchets',
                    wirtschaftliche_dienstleistungen: 'Services économiques'
                },
                title: 'Branche'
            },
            employmentStatusOptions: {
                employee: 'Salarié',
                pensioner: 'Retraité',
                privateer: 'Privé',
                public_official: 'Fonctionnaire',
                self_employed: 'Indépendant',
                student: 'Etudiant/Stagiaire'
            },
            exploration_warning: 'Nous n\'avons pas reçu suffisamment d\'informations de votre part pour pouvoir vérifier le caractère approprié de l\'investissement. Il y a donc un risque que vous puissiez acquérir un placement en capital qui ne vous convient pas et dont vous êtes incapable d\'évaluer les risques.',
            job_category: 'Catégorie professionnelle',
            job_education: {
                current_job: {
                    data: {
                        asset_manager: 'Gestionnaire d\'actifs',
                        bankangestellter: 'Employé de banque',
                        finanzberater: 'Conseillers financiers',
                        finanzmakler: 'Courtier en financement',
                        finanzvermittler: 'Intermédiaires financiers',
                        handwerker: 'Artisant',
                        investment_banker: 'Banquier responsable des investissements',
                        kfm_angestellter: 'Employé commercial',
                        leitende_funktion_controlling: 'Fonction de direction du contrôle de gestion',
                        leitende_funktion_finanzabteilung: 'Chef de la direction financière',
                        rentner_pensionaer: 'Retraité',
                        sonstige: 'Autres professions non financières',
                        steuerberater: 'Conseiller fiscal',
                        student_auszubildender: 'Etudiant/Apprenti',
                        wirtschaftspruefer: 'Commissaire aux comptes'
                    },
                    label: 'Profession actuelle'
                },
                educational_achievement: {
                    data: {
                        fachhochschul_hochschulreife: 'Haute école spécialisée ou diplôme d\'accès à l\'enseignement supérieur',
                        haupt_volks_realschulabschluss: 'Certificat de fin d\'études secondaires',
                        hochschulabschluss: 'Diplôme universitaire',
                        kaufmaennische_berufsausbildung: 'Formation professionnelle commerciale',
                        keiner_der_genannten_abschluesse: 'Aucun des choix mentionnés ci-dessus',
                        promotion_habilitation: 'Doctorat/réhabilitation',
                        sonstige_berufsausbildung: 'Autre formation professionnelle'
                    },
                    label: 'Niveau de scolarité le plus élevé'
                },
                past_job: {
                    data: {
                        asset_manager: 'Gestionnaire d\'actifs',
                        bankangestellter: 'Employé de banque',
                        finanzberater: 'Expert financier',
                        finanzmakler: 'Courtier en financement',
                        finanzvermittler: 'Intermédiaire financier',
                        handwerker: 'Artisant',
                        investment_banker: 'Banquier responsable des investissements',
                        kfm_angestellter: 'Employé commercial',
                        leitende_funktion_controlling: 'Fonction de direction du contrôle de gestion',
                        leitende_funktion_finanzabteilung: 'Chef des finances',
                        rentner_pensionaer: 'Retraité',
                        sonstige: 'Autres professions non financières',
                        steuerberater: 'Conseiller fiscal',
                        student_auszubildender: 'Etudiant/Apprenti',
                        wirtschaftspruefer: 'Commissaire aux comptes'
                    },
                    label: 'Ancienne profession'
                },
                title: 'Profession et formation'
            }
        },
        investments: {
            bonds: 'Emprunts, certificats',
            equity_interest: 'Parts en placement (ex. fonds de placement à capital fixe)',
            how_long: {
                label: 'Depuis combien de temps avez-vous de l\'expérience avec ce type d\'investissement ?',
                options: {
                    btw_3_5: '3-5 ans',
                    more_than_5: 'plus de 5 ans',
                    upto_3: 'jusqu\'à 3 ans'
                }
            },
            how_many: {
                label: 'Combien de transactions effectuez-vous en moyenne par an ?',
                options: {
                    btw_5_10: '5 - 10',
                    more_than_10: 'Plus de 10',
                    upto_5: 'Jusqu\'à 5'
                }
            },
            how_much: {
                label: 'Quelle a été le montant moyen de votre investissement jusqu\'à présent ?',
                options: {
                    more_than_10500: 'Plus de 10.500 €',
                    upto_10500: 'Jusqu\'à 10.500 €',
                    upto_2500: 'Jusqu\'à 2.500 €',
                    upto_5000: 'Jusqu\'à 5.000 €'
                }
            },
            knowledge: {
                label: 'Connaissances disponibles ?',
                value_1: 'Oui',
                value_2: 'Non'
            },
            open_stock: 'Fonds ouverts',
            shares: 'Actions',
            subordinate_investments: 'Actifs financiers subordonnés (p. ex. prêts participatifs subordonnés)'
        },
        investor: {
            add_investor: 'Ajouter un autre investisseur',
            edit_user: 'Modifier',
            information: '(En cours de modification)',
            title: 'Qui investit ?'
        },
        modal_controls: {
            back: 'Retour',
            next: 'Suivant'
        },
        VermAnlG: {
            exploration: {
                description: 'En tant qu\'intermédiaires en investissement, nous sommes tenus par la loi d\'obtenir des informations sur votre expérience et votre connaissance des transactions impliquant certains types d\'investissements financiers ainsi que sur votre formation et vos activités professionnelles. Le partage de ces informations est volontaire et est dans votre propre intérêt.',
                description_hint_1: 'Nous sommes tenus par la loi de vous informer que nous ne disposons pas d\'informations suffisantes pour confirmer que vous avez les connaissances et l\'expérience nécessaire afin d\'évaluer raisonnablement les risques de la transaction proposée.',
                description_hint_2: 'Malheureusement, vous n\'avez pas répondu aux questions, ou partiellement. Si vous poursuivez le processus d\'investissement et le validez, nous vous signalons que nous n\'aurons pas été en mesure d\'effectuer le contrôle adéquat requis par la loi. Nous ne sommes donc pas en mesure d\'évaluer si l\'investissement proposé vous convient compte tenu de vos connaissances et de votre expérience. Il existe donc un risque que vous puissiez valider un placement qui ne vous convient pas et dont vous ne pouvez pas évaluer adéquatement les risques.',
                not_perform: 'Je ne veux pas que le contrôle d\'adéquation soit effectué.',
                perform: 'J\'aimerais que le contrôle d\'adéquation soit fait.',
                title: 'Quelles expériences et quelles connaissances avez-vous ?'
            },
            headline: 'Investissement',
            investmentVermAnlG: {
                account_number: 'Numéro de compte',
                bank_account: 'Vos coordonnées bancaires :',
                data_should_be_saved: 'Sauvegarder mes coordonnées bancaires de manière cryptées pour mon prochain investissement.',
                debit: 'Débit',
                debit_mandate: 'J\'autorise Secupay AG, Goethestr. 6, 01896 Pulsnitz, Allemagne, à prélever un montant unique sur mon compte par prélèvement automatique. En même temps, je donne instruction à ma banque d\'encaisser la domiciliation prélevée sur mon compte par secupay AG. Cette domiciliation est utilisée pour encaisser le montant de l\'investissement sélectionné ci-dessus. Note : Je peux demander un remboursement du montant débité dans les huit semaines suivant la date de débit. Les conditions convenues avec ma banque s\'appliquent également.',
                email_confirmation: 'Les données de compte vous seront envoyées avec les documents contractuels par e-mail.',
                ibanbic: 'IBAN',
                investsum: 'Montant',
                invest_bic: 'Code de Banque',
                invest_iban: 'Numéro de compte',
                payment_method: 'Mode de paiement',
                prepay: 'Virement bancaire',
                small_investor: 'Si le montant total de tous les investissements que j\'ai acquis à ce jour auprès de l\'émetteur et des investissements que j\'acquiers dans ce cas auprès du même émetteur pour le montant de mon investissement susmentionné dépasse 1.000 €, je confirme dans le cadre d\'une auto-déclaration (i) que ce montant total ne dépasse pas le double de mon revenu net mensuel moyen ou 25.000 € ou (ii) que le montant total ne dépasse pas 10.000 € et que je dispose d\'un patrimoine disponible sous forme de dépôts bancaires et d\'instruments financiers d\'au moins 100.000 €.',
                title: 'Combien souhaitez-vous investir ?',
                use_no_bank_account: 'Utiliser d\'autres coordonnées bancaires :'
            },
            store_success: {
                description: 'Afin de vous donner l\'opportunité d\'investir dans l\'univers complet des produits du Groupe Exporo, nous vous recommandons de vous identifier personnellement dès aujourd\'hui.',
                headline: 'Investis avec succès',
                ident_required_description: 'Zum Abschluss Ihres Investments ist eine Identifizierung nötig. Dies können Sie einfach und digital in wenigen Minuten über unseren Identifizierungsdienstleister tun. Klicken Sie dazu einfach auf diesen Link:',
                ident_button: 'S\'inscrire gratuitement'
            },
            subline: 'Investissez dans le projet dès aujourd\'hui :',
            summary: {
                additional_interest: 'Intérêt additionnel :',
                description: {
                    hint: 'Note',
                    text: 'Les données suivantes sont extraites de votre enregistrement précédent. Veuillez vérifier l\'exactitude des données ou corrigez-les en conséquence et complétez la transaction en cliquant sur "Investir maintenant et payer" si toutes les données sont correctes.'
                },
                download: 'téléchargement non défini',
                dsgvo_secupay_hint: 'Par la présente, j\'autorise le traitement de mes données dans le cadre de la conclusion du contrat et leur transmission à notre prestataire de services de paiement SECUPAY.',
                interest: 'Intérêts :',
                investsum: 'Montant de l\'investissement :',
                i_have_read_all_docs: 'Je confirme que j\'ai reçu, enregistré et lu la fiche de renseignements sur les placements de fortune et les données relatives aux obligations légales par e-mail et que je les accepte par la présente.',
                project: 'Projet :'
            },
            VIB: {
                information: {
                    edit: 'Modifier'
                },
                information_container: {
                    edit: 'Modifier',
                    title: 'Vos coordonnées'
                },
                information_right: {
                    born_at: 'Né(e) le'
                },
                info_text: 'Info : Après avoir coché les cases de vérification, les documents mentionnés ci-dessus vous seront envoyés par e-mail.',
                legal_download: 'Téléchargez les informations légales obligatoires',
                legal_obligation_text_a: 'J\'ai pris connaissance de l\'avertissement légal figurant sur la fiche de renseignements sur les placements et je le confirme en saisissant mes données. L\'acquisition de cet investissement est associée à des risques considérables et peut conduire à la perte totale des actifs investis.',
                legal_obligation_text_b: 'J\'ai lu et imprimé et/ou sauvegardé les informations requises par la loi.',
                signature: {
                    description: 'Veuillez entrer votre nom et prénom complet, la date et le lieu pour confirmer :',
                    example: 'Exemple : Max Mustermann 01.01.2017 Hamburg',
                    placeholder: 'Prénom Nom Date Ville'
                },
                vib_download: 'Téléchargez la fiche d\'information sur les placements',
                vib_text_a: 'J\'ai pris connaissance de l\'avertissement légal figurant sur la fiche de renseignements sur les placements et je le confirme en saisissant mes données. L\'acquisition de cet investissement est associée à des risques considérables et peut conduire à la perte totale des actifs investis.',
                vib_text_b: 'J\'ai pris connaissance de l\'avertissement légal figurant au point 1 de la fiche de renseignements sur les placements. L\'avertissement se lit comme suit : "L\'acquisition de cet investissement comporte des risques considérables et peut entraîner la perte totale des actifs investis." En validant et en entrant mes données ci-dessous, je confirme que je les ai lues et comprises.'
            }
        },
        WpHGLite: {
            explorationWpHGLite: {
                availableInvestments: 'Il ne reste plus que des obligations non définies à souscrire.',
                checkbox: 'J\'ai compris le message et aimerais poursuivre',
                exploration_hint: {
                    state_0: 'Nous n\'avons pas reçu suffisamment d\'informations de votre part pour pouvoir vérifier le caractère approprié de l\'investissement. Il y a donc un risque que vous puissiez acquérir un placement en capital qui ne vous convient pas et dont vous ne pouvez pas évaluer les risques.',
                    state_1: 'Merci beaucoup pour ces informations. Sur la base de ces informations, nous avons effectué un test d\'adéquation. Si vous poursuivez le processus d\'investissement et souscrivez le capital proposé, nous attirons votre attention sur le fait que le test d\'adéquation a montré que le capital investi proposé ne vous convient pas compte tenu de vos connaissances et expériences personnelles. Il y a donc un risque que vous puissiez acquérir un placement en capital qui ne vous convient pas et dont vous ne pouvez pas évaluer les risques.'
                },
                nextButtonText_1: 'Ouvrez un dépôt maintenant et validez votre souscription',
                nextButtonText_2: 'Soumettez votre souscription maintenant',
                nextButtonText_3: 'Ouvrez un dépôt et soumettez votre souscription maintenant',
                no_availableInvestments: 'Il n\'y a plus d\'obligations à souscrire.',
                subtitle: 'En tant qu\'EPH, nous sommes des courtiers en valeurs mobilières et sommes donc tenus d\'obtenir des informations sur votre expérience et vos connaissances concernant les transactions portant sur certains types d\'actifs financiers ainsi que sur votre formation et vos activités professionnelles. EPH n\'agit qu\'en tant qu\'intermédiaire et ne fournit pas de conseil en investissement. Par conséquent, EPH n\'est pas en mesure d\'évaluer si les investissements présentés correspondent à vos objectifs d\'investissement. Chaque investisseur Exporo est communément classé comme client privé.',
                title: 'Quelles expériences et quelles connaissances avez-vous ?'
            },
            headline: 'Investissement',
            investmentWpHGLite: {
                checkbox: {
                    church_tax_deduction: 'NOT TRANSLATED because this segment is not relevant outside Germany '
                },
                description: (formatPrice: any) => `Entrez ici la quantité désirée. Le montant de votre investissement sera automatiquement calculé sur la base du montant nominal de ${formatPrice} € :`,
                info_text: 'Info : Après avoir coché les cases des attestations du vérificateur pour le prospectus des valeurs mobilières et pour les renseignements sur le courtier, ces deux documents vous seront envoyés par mail.',
                payout_form: {
                    investment_hint: {
                        hint_a: 'Veuillez saisir votre IBAN pour le prélèvement automatique. Les distributions de rendement futures seront créditées à ce compte.',
                        hint_b: 'Une fois votre demande de souscription acceptée, les coordonnées bancaires vous seront envoyées par e-mail. Veuillez indiquer votre IBAN pour les distributions de rendement futures.'
                    },
                    investtype: 'Mode de paiement',
                    label: 'Sélectionnez ici le mode de paiement de votre choix :',
                    type_debit: 'Débit',
                    type_prepay: 'Virement bancaire (prépaiement)'
                },
                pieces: 'Correspondance de la part',
                remaining_pieces: 'Parts restantes :',
                single_unit_threshold: 'Si le montant total de l\'ensemble des titres que j\'ai acquis précédemment auprès de l\'émetteur et des titres que j\'acquiers actuellement auprès du même émetteur pour le montant de mon investissement susmentionné dépasse 1.000 €, je confirme dans le cadre d\'une auto-déclaration que (i) ce montant total ne dépasse pas deux fois mon revenu net moyen ou 10.000 € ou (ii) que le montant total ne dépasse pas 10.000 € et que je dispose librement des actifs sous la forme de dépôts bancaires et instruments financiers pour un montant minimum de 100.000 €.',
                sum: '€ Montant de l\'investissement',
                title: 'Votre investissement'
            },
            no_available_pieces: 'Plus d\'obligations disponibles !',
            subline: 'Investissez dans le projet dès aujourd\'hui :',
            summaryWpHGLite: {
                accept_to_save: 'Je confirme/nous confirmons que j\'ai/nous avons reçu les documents suivants sur un support de données durable (sous la forme d\'une pièce jointe PDF par e-mail) pour mes/nos dossiers :',
                description: 'Les données suivantes sont extraites de votre enregistrement précédent. Veuillez vérifier l\'exactitude des données ou corrigez-les en conséquence puis complétez la transaction en cliquant sur "Investir maintenant et payer" si toutes les données sont correctes.',
                information: {
                    headline: 'Investisseur',
                    user_edit: 'Modifier les données'
                },
                information_container: {
                    information_right: 'Banque de dépôt',
                    label: 'EPH (Mediateur)',
                    protocol: 'Protocole du test de suffisance',
                    title: 'Télécharger'
                },
                information_right: {
                    debit: 'Débit',
                    headline: 'Projet : ',
                    invest_sum: 'Montant de l\'investissement : ',
                    isin: 'Code ISIN :',
                    payment_method: 'Mode de paiement :',
                    project_name: 'Nom du projet :',
                    transfer: 'Virement bancaire',
                    wkn: 'WKN :'
                },
                label: 'Conseil',
                risk_hint: (email: string) => `Avant de réaliser votre investissement, vous devez comprendre les services offerts et les risques encourus. Vous trouverez ci-dessous du matériel d'information téléchargeable. Ces informations vous seront également envoyées par e-mail à  ${email}.`,
                title: 'Schéma'
            }
        }
    },
    cockpit: {
        close_cockpit: 'Quitter le cockpit d\'investissement',
        cockpitDropbar: {
            bond: 'Portfolio',
            invest_plan: 'Plan d\'investissement',
            mezz: 'Financement'
        },
        cockpitPanel: {
            less: 'Moins',
            more: 'Plus'
        },
        cockpitSlider: {
            less: 'Moins',
            more: 'Plus'
        },
        cockpitTimeline: {
            title: 'Mises à jour du projet',
            subtitle: 'Nous publions régulièrement des mises à jour de nos projets afin de vous informer de leur évolution. Cliquez sur la mise à jour de votre choix et obtenez de plus amples informations.',
            no_update_hint: "Nos mises à jour de projets vous tiennent régulièrement informés de l'évolution de votre financement. Les premières mises à jour des projets seront publiées dans les prochaines semaines."
        },
        cockpit_loading_hint: 'Chargement de votre cockpit d\'investissement ...',
        customer_nr: 'Numéro Client :',
        detailPanel: {
            all_pieces_offered: 'Max. Nombre de pièces déjà offertes',
            buy_pieces: 'Offres de part en vente',
            reject_offer: 'Retrait de l\'offre de vente',
            label: 'Centre de négociation',
            not_yet_ready_to_trade: 'Projet en financement',
            offered_pieces: 'Quantitée proposée',
            piece: 'Part(s)'
        },
        detailView: {
            back_button: 'RETOUR'
        },
        ident: {
            cta: 'S\'identifier',
            description: 'Une identification personnelle est nécessaire pour investir dans les obligations Exporo à l\'avenir.',
            question_hint: 'Vous avez encore des questions sur l\'identification ? Contactez-nous par e-mail à investment@exporo.fr ou du lundi au vendredi de 10h à 17h au 01 81 22 41 68.',
            states: {
                done: 'Identification',
                open: 'Ouvrir',
                running: 'En cours',
                start: 'Démarrer l\'identification'
            },
            successful: 'Merci ! Vous vous êtes identifié avec succès !',
            title: 'Identification'
        },
        investlist: {
            buy: 'Acheter',
            details: 'Details',
            invest_menu: {
                label: 'Trier par :'
            }
        },
        kpi: {
            historyPanel: {
                date: 'Investi le',
                documents: 'Documents',
                downloads: 'Téléchargements',
                id: 'Numéro de contrat',
                investment: 'Investissement',
                no_documents: 'Aucun document disponible',
                payback: 'Prochain versement',
                received_payback: 'Déjà remboursé',
                title: 'Historique des investissements'
            }
        },
        list_financing_investments: {
            empty_state_message: {
                all: 'Vous n\'avez actuellement aucun investissement',
                disbursed: 'Vous n\'avez actuellement aucun investissement décaissé',
                ongoing: 'Vous n\'avez actuellement aucun investissement en cours'
            },
            headline: 'Vos investissements Exporo'
        },
        list_financing_payments: {
            defaultActiveCol: 'Date de versement',
            empty_state_message: {
                all: 'Vous n\'avez actuellement aucun paiement',
                coming: 'Vous n\'avez actuellement aucun paiement de rendement en attente.',
                disbursed: 'Vous n\'avez actuellement aucun paiement de rendement versé'
            },
            headline: 'Vos versements',
            payback_started: 'Par défaut'
        },
        menu: {
            contact_center: 'Notifications',
            documents: 'Documents',
            edit_user: 'Données personnelles',
            friends: 'Parrainez un ami',
            investors: 'Mes investisseurs',
            logout: 'Déconnexion',
            password_change: 'Modifier le mot de passe',
            questions: 'Vous avez une question ?',
            questions_url: 'https://exporo.fr/kontakt/',
            title: 'Menu'
        },
        pages: {
            contactCenter: {
                advertisment_notification_control: 'Autorisation de publicité',
                description: `Vous avez le choix : <br> Décidez vous-même sur quels sujets nous vous informons et par quels canaux nous pouvons vous contacter.`,
                title: 'Notifications'
            },
            detail: {
                documents_title: 'Documents',
                kpis: {
                    created_at: 'Investi le',
                    expected_interest: 'Rendement par an',
                    expected_total_interest: 'Rendement total attendu par an ',
                    interest: 'Rendement annuel',
                    investment: 'Investissement',
                    investor: 'Investisseur',
                    isin: 'Code ISIN',
                    payoutdate: 'Échéance',
                    payoutsum: 'Montant du versement',
                    period: 'Durée',
                    project: 'Projet',
                    signing_id: 'Identifiant du contrat',
                    wkn: 'WKN',
                    pieces: 'Quantité',
                    piece_value: "Valeur d’une part"
                },
                no_documents: 'Aucun document disponible',
                reportings_charts: {
                    series_a: 'Est',
                    series_b: 'Prévu',
                    title_maintenance_reserve: 'Réserve d\'entretien (€)',
                    title_payout_euro: 'Distribution de dividendes par trimestre [€]',
                    title_payout_percent: 'Rendement du dividende (%)',
                    title_rental_income: 'Revenus locatifs nets [€]'
                },
                reportings_charts_title: null,
                reportings_hint_no_reportings: 'Prochainement, vous trouverez ici des rapports détaillés ainsi que d\'autres informations et chiffres clés sur le développement du bien.',
                reportings_table_header: {
                    acquittance_payments: 'Paiement des remboursements',
                    income_outcome: 'Recettes/dépenses',
                    interest_payments: 'Paiement des intérêts',
                    investor_capital: 'Capital investisseur',
                    maintenance_cost: 'Coûts de maintenance',
                    maintenance_reserve_funds: 'Réserve d\'entretien',
                    management_costs: 'Frais administratifs',
                    operating_costs: 'Frais d\'exploitation',
                    payout_euro: 'Distribution en €',
                    payout_percent: 'Répartition du dividende en %.',
                    property_management: 'Gestion de la propriété',
                    real_estate_result: 'Résultat de la (des) propriété(s)',
                    rental_income: 'Revenus locatifs',
                    rental_loss: 'Perte de loyer'
                },
                reportings_table_title: 'Bénéfice net trimestriel',
                reportings_title: 'Rapport du Portfolio',
                reportings_title_current_development: 'Evolution actuelle'
            },
            disbursed: {
                headline: 'Paiements de rendement décaissés'
            },
            documents: {
                baseDataFiles: 'Modifications apportées au formulaire des données de base',
                depotFiles: 'Document du dépôt',
                documentsYearlyList: 'Documents fiscaux',
                explorationFiles: 'Recherche',
                filesPayback: 'Réglement d\'interêts',
                quarterlyReports: 'Rapports trimestriels sur Exporo',
                title: 'Documents'
            },
            financing: {
                additionalEmptyText: 'Vous n\'avez actuellement aucun paiement.',
                filter: {
                    investments: {
                        allInvestments: 'Tous les investissements',
                        disbursedInvestments: 'Investissements décaissés',
                        ongoingInvestments: 'Investissements en cours'
                    },
                    payments: {
                        allPayments: 'Tous les versements',
                        comingPayments: 'Retours en attente',
                        pastPayments: 'Paiements de rendement'
                    }
                },
                keyfigures: {
                    count_running_investments: 'Nombre d\'investissements en cours',
                    return_running_investments: 'Ø-Rendement annuel des investissements en cours',
                    sum_running_investments: 'Total des investissements actuels'
                },
                label: 'Chiffres clés de vos investissements dans Exporo Financement',
                landing: {
                    action_button: 'À propos d\'Exporo',
                    description: 'Avec le financement de l\'investissement immobilier numérique Exporo, vous profitez déjà des avantages du développement de projets immobiliers à partir d\'un investissement minimum de 500€ - sans frais supplémentaires et sans effort.',
                    label: 'Comment ça marche ?',
                    text: 'Environ la moitié du portefeuille immobilier d\'Exporo est financé par des investisseurs d\'Exporo et l\'autre moitié par un emprunt bancaire. Les revenus locatifs courants sont utilisés pour verser des distributions trimestrielles de rendement aux investisseurs et, en même temps, l\'emprunt bancaire est remboursé et le développement professionnel et l\'entretien de l\'immeuble sont assurés.',
                    title: 'Exporo'
                },
                list_headers: {
                    invested_at: 'Investi sur',
                    investment: 'Investissement',
                    investor: 'Investisseur',
                    project: 'Projet',
                    return_interest: 'Rendement par an',
                    running_time: 'Terme'
                },
                list_headers_payments: {
                    id: 'ID',
                    payout_date: 'Date de paiement',
                    payout_sum: 'Montant du versement',
                    project: 'Projet'
                },
                tabbar: {
                    investments: 'Investissements',
                    payments: 'Versements',
                    projects: 'Investissements',
                }
            },
            friends: {
                copy_link: 'Copier le lien',
                description: 'Obtenez un bonus de 100 EUR pour chaque parrainage réussi. Partagez votre lien de parrainage personnel avec vos amis, vos connaissances et votre famille.',
                explanation: {
                    headline: 'Comment ça marche ?',
                    hint: `Vous trouverez ici nos conditions de participation: <a href="https://exporo.fr/amis-conditions-de-participation/" target="_blank">Conditions de participation </a>`,
                    step_headline_1: '1. Partagez',
                    step_headline_2: '2. Inscrivez-vous',
                    step_headline_3: '3. Recevez le bonus',
                    step_text_1: 'Partagez votre lien de parrainage personnel avec vos amis, vos connaissances et votre famille.',
                    step_text_2: 'Après votre inscription sur Exporo, votre filleul recevra un code promotionnel de 100 EUR pour le premier investissement.',
                    step_text_3: 'Si votre recommandation conduit à un investissement, vous recevrez également un code promotionnel de 100 EUR pour votre prochain investissement.'
                },
                link: (uid: string) => `https://investissements.exporo.fr/amis/?friend_id=${uid}`,
                share_link: 'Partagez votre lien :',
                title: 'Parrainez un ami'
            },
            investors: {
                add_investor: 'Ajouter un investisseur',
                admin_hint: 'modifiable uniquement par les admins',
                entity_authorized_as: 'Autorisé à représenter en tant que',
                entity_commercial_register_file: 'Extrait du registre du commerce',
                entity_commercial_register_number: 'Numéro de registre du commerce',
                entity_local_court: 'Tribunal local compétent',
                entity_name: 'Nom de l\'entreprise',
                entity_shareholders_file: 'Liste des participants',
                entity_status: 'Statut du traitement',
                entity_status_accepted: 'Vérifié',
                entity_status_open: 'En cours de vérification',
                entity_tax_number: 'Numéro d\'identification fiscale',
                entity_tax_office: 'Bureau des impôts compétent',
                entity_types: {
                    ag: 'Société Anonyme (SA)',
                    default: 'Choisir',
                    gmbh: 'Société anonyme à responsabilité limitée (SARL)',
                    gmbhcokg: 'SARL & Partenariat limité',
                    kg: 'Partenariat limité',
                    ug: 'Entreprise entrepreneuriale'
                },
                entity_type_of_company: 'Forme de la société',
                form_hint: 'Créez un profil investisseur ici.',
                investor: 'Investisseur',
                investor_not_found_error: 'L\'investisseur n\'existe pas.',
                label: 'Annuler',
                legal_entity_checkbox: 'en tant que personne morale.',
                legal_entity_label: 'Personne morale',
                legal_entity_verification: 'Je confirme être le représentant légal de la société.',
                submit_text_create: 'Créer',
                submit_text_update: 'Mettre à jour',
                title: 'Mes investisseurs'
            },
            investplan: {
                interest: 'Participation',
                kpis: {
                    contributions_phase_ends_at: 'Fin du paiement par acomptes provisionnels',
                    contributions_phase_starts_at: 'Début du paiement par acomptes provisionnels',
                    contributions_sum: 'Montant investi',
                    contributions_sum_month: 'Taux mensuel',
                    expected_interest_rate: 'Taux d\'intérêt ciblé par an',
                    id: 'ID du plan d\'épargne',
                    interestrate_guaranteed: 'Intérêts de base par an',
                    investor: 'Investisseur',
                    next_payout: 'Reversement'
                },
                panel_title_documents: 'Documents',
                panel_title_projects: 'Projets investis',
                project: 'Projet',
                title: 'Plan d\'investissement Exporo'
            },
            portfolio: {
                data_pending_type: {
                    bond_purchase: 'Achat d\'obligations',
                    bond_purchase_secondary_market: 'Achat d\'obligations (Centre des négociations)'
                },
                keyfigures: {
                    equity_investments_length: 'Nombre actuel de biens immobiliers existants',
                    invested_total_equity_sum: 'Somme des objets de portefeuille actuels'
                },
                kpi_title: 'Chiffres clés de vos investissements Exporo',
                landing: {
                    action_button: 'Vers les projets',
                    description: 'Avec Exporo, vous pouvez bénéficier des avantages de la propriété immobilière à partir de 1 000 € - sans frais supplémentaires. Regardez la vidéo pour découvrir comment une valeur auparavant inflexible avec Exporo peut être transformée en un investissement négociable dans l\'immobilier avec lequel vous pouvez constituer des actifs à long terme.',
                    label: 'Comment ça marche ?',
                    text: 'Environ la moitié du portefeuille immobilier d\'Exporo est financé par des investisseurs d\'Exporo et l\'autre moitié par un emprunt bancaire. L\'avantage de l\'action Exporo : vous n\'avez aucun effort, vous n\'avez pas à vous soucier du développement, de la location et de l\'entretien et vous n\'êtes pas lié à la propriété - car vous pourrez revendre vos parts Exporo à partir de mi-2020.',
                    title: 'Exporo'
                },
                list_empty_states: {
                    filter_investments: 'Vous n\'avez actuellement aucun investissement pour ce filtre.',
                    paied_investments: 'Vous n\'avez actuellement aucun investissement décaissé.',
                    running_investments: 'Vous n\'avez actuellement aucun investissement en cours.'
                },
                list_headers: {
                    already_received: 'Déjà distribué',
                    current_state: 'Statut',
                    expected_payout: 'Rendement par an',
                    invested_at: 'Date de souscription',
                    investment: 'Somme',
                    next_payout: 'Prochain versement',
                    project: 'Projet',
                    running_time: 'Durée',
                    type: 'Type'
                },
                list_title: 'Votre inventaire Exporo | Statut : Investissement validé',
                list_title_pending: 'Votre inventaire Exporo | Statut : En cours de traitement',
                list_title_tooltip: 'Vos placements vont désormais être enregistrés dans votre portefeuille numérique. Ce processus peut prendre jusqu\'à deux semaines pour les placements initiaux, selon le statut de votre identification personnelle, l\'ouverture de votre portefeuille numérique et la transaction du montant de votre placement. Vous pouvez suivre l\'évolution de l\'opération dans la barre d\'état sous votre investissement.',
                list_undertitle: 'La liste suivante de vos placements reflète vos actions nominatives dans  votre portefeuille  numérique.',
                list_undertitle_pending: 'Vos placements sont désormais enregistrés dans votre portefeuille numérique. Ce processus peut prendre jusqu\'à deux semaines pour les placements initiaux, selon le statut de votre identification personnelle, l\'ouverture de votre portefeuille numérique et la transaction du montant de votre placement. Vous pourrez constater l\'évolution de l\'opération  dans la barre d\'état, sous votre investissement.',
                pending_filter: {
                    allInvestments: 'Tous les investissements',
                    pending: 'En cours de traitement'
                },
                progress_bar: {
                    booking: 'Investissement validé',
                    depot: {
                        done: 'Ouverture du dépôt',
                        not_done: 'Ouverture du dépôt'
                    },
                    ident: {
                        done: 'Identifié',
                        not_done: 'Démarrer l\'identification'
                    },
                    paid: {
                        done: 'Paiement effectué',
                        not_done: 'Paiement en cours'
                    }
                }
            }
        },
        tabbar: {
            financing: 'Financement',
            investplan: 'Plan d\'investissement',
            portfolio: 'Portfolio'
        },
        title: 'Cockpit d\'investissement'
    },
    contact: {
        checkbox_ag_1: 'Par la présente, je suis d\'accord avec la/le',
        checkbox_ag_2: 'Déclaration de consentement au formulaire de contact',
        checkbox_ag_3: 'et à la',
        checkbox_ag_4: 'déclaration de confidentialité',
        checkbox_ag_5: 'de Exporo AG.',
        checkbox_gmbh_5: 'de Exporo GmbH.',
        contact_placeholder_1: 'Nom *',
        contact_placeholder_2: 'Email *',
        contact_placeholder_3: 'Objet *',
        contact_placeholder_4: 'Message *',
        cta: 'Envoyer',
        exporo_contact: 'Comment pouvons-nous vous aider ? Prenez contact avec nous.',
        exporo_description: 'Avec Exporo, vous pouvez bénéficier de projets immobiliers attrayants - à partir de 500 euros. Depuis notre siège à Hambourg, nous mettons en relation investisseurs et promoteurs de projets. Vous aussiez, rejoignez-nous : Écrivez-nous simplement et nous vous parlerons de nos offres ou vous aiderons à réaliser votre projet.',
        title: 'Contact'
    },
    customerFeedback: {
        title: 'AVIS DE NOS INVESTISSEURS',
        link_text: "Plus d'avis",
        link_url: 'https://investissements.exporo.fr/avis-clients/'
    },
    error_form: {
        error_message: 'Veuillez choisir votre titre.'
    },
    form: {
        adress: 'Adresse',
        city: 'Ville',
        email: 'Adresse e-Mail',
        land: 'Pays',
        legitimation_document: 'Pièce d\'identité',
        primary_phone: 'Numéro principal',
        save: 'Sauvegarder',
        secondary_phone: 'Numéro de téléphone secondaire',
        to_be_filled: 'doit être renseignée.',
        zip: 'Code postal'
    },
    header_phone_no: '01 81 22 41 68\n',
    header_phone_office_hours: 'LUN - VEN 10h - 17h',
    header_phone_text: 'SERVICE HOTLINE:',
    iban: {
        error_cannot_check: 'Iban n\'a pas pu être vérifié. Veuillez entrer votre BIC.',
        error_not_valid: 'Veuillez entrer un IBAN valide.'
    },
    invest_button: {
        label: 'Investir'
    },
    listView: {
        deselected_all: 'tout désélectionner',
        entries_from: 'de',
        entries_per_page: 'Nombre d\'entrées par page',
        export_csv: 'Exporter le fichier au format CSV',
        next: 'suivant',
        prev: 'précédent',
        print: 'Imprimer',
        search_project: 'Chercher un projet',
        selected_entries: 'Entrées sélectionnées'
    },
    person: {
        birthday: 'Date de naissance',
        birth_city: 'Ville de naissance',
        birth_land: 'Pays de naissance',
        birth_name: 'Nom de naissance',
        citizenship: 'Nationalité',
        female: 'Mme.',
        first_name: 'Prénom',
        last_name: 'Nom',
        male: 'M.',
        marital_status: 'Statut familial',
        titles: {
            dr: 'Dr.',
            dr_med: 'Dr. med.',
            prof: 'Prof.',
            prof_dr: 'Prof. Dr.'
        },
        title_label: 'Titre'
    },
    progress_bar: {
        funding_in_percentage: 'financé',
        remaining: 'reliquat'
    },
    query_settings: {
        headline: 'Caractéristiques expérimentales',
        toggle: 'Animation :'
    },
    secondaryMarket: {
        buyButton: {
            cta: 'Acheter'
        },
        buySuccess: {
            title: 'Une offre d\'achat a été soumise avec succès.'
        },
        buyTransaction: {
            checkbox_1: 'Par la présente, je suis d\'accord avec le/la',
            checkbox_2: 'Conditions Générales',
            checkbox_3: 'Vers le centre de négociation Exporo.',
            cta: 'Soumettre une offre d\'achat',
            label_1: 'Prix par part :',
            label_2: 'Quantité disponible :',
            label_3: 'Combien de parts souhaitez-vous acheter ?',
            label_4: 'Parts',
            label_5: 'Votre offre d\'achat :',
            small_hint: 'Note : Une vente via le Centre de Négoce Exporo est imposable, c\'est-à-dire que tout bénéfice est imposable et sont soumis à l\'impôt sur les plus-values (plus la surtaxe de solidarité).',
            title_1: 'Offre d\'achat pour',
            title_2: 'Céder',
            valid_input_1: 'Veuillez vérifier vos entrées !'
        },
        modalContainer: {
            label_1: 'IBAN :',
            label_2: 'Données complètes',
            no_depot_available: 'Pour pouvoir présenter une offre d\'achat, il est nécessaire d\'ouvrir un compte de titres.',
            reminder_text_1: 'Veuillez entrer votre IBAN pour qu\'un dépôt puisse être mis en place.',
            reminder_text_2: 'Vos données personnelles doivent d\'abord être complètes.'
        },
        offerSuccess: {
            title: 'a été mis en vente en bourse.'
        },
        offerTransaction: {
            cta: 'Soumettre une offre de vente',
            label_1: 'Votre capital disponible :',
            label_2: 'Combien de parts souhaitez-vous acheter ?',
            label_3: 'Part',
            label_4: 'A quel prix par part voulez-vous vendre ?',
            label_5: '€',
            label_6: 'Frais de publicité :',
            label_7: 'Votre offre de vente :',
            sending_offer: 'Votre offre d\'achat sera publiée',
            small_hint: 'Note : Une vente via le centre de négociation Exporo est soumise à l\'impôt, c\'est-à-dire que les bénéfices éventuels sont soumis à l\'impôt sur les plus-values (plus la surtaxe de solidarité).',
            store_error: 'Une erreur est survenue. Veuillez réessayer.',
            title: 'Créez une offre de vente pour',
            tooltip_1: 'Calcul de parts pour ce projet : 1 part = 1000 €.',
            tooltip_2: 'En cas de vente réussie, les frais de publicité suivants seront facturés :  0,5% de l\'offre de vente.',
            valid_input_1: 'Aucune part à vendre disponible !',
            valid_input_2: 'Vente impossible'
        },
        offerRejection: {
            title: "Retirer l'offre de vente pour",
            already_sold: 'Parts déjà vendues :',
            in_progress: 'En cours :',
            remaining_pieces: 'Parts ouvertes :',
            sale_price_per_piece: 'Valeur par part :',

            store_error: "Une erreur s'est produite lors du retrait de l'offre de vente. Veuillez réessayer.",
            small_hint_1: "L'offre de vente peut être retirée. Aucune part n'a encore été vendue.",
            small_hint_2: "L'offre de vente ne peut être retirée que pour les parts ouvertes. Les parts déjà vendues ou en cours d'échange sont exclues du retrait de l'offre de vente et restent en vigueur ou seront exécutées.",
            cta: 'Retirer les parts ouvertes',
            sending_rejection: "L'offre de vente est supprimée",
            no_rejectable_pieces: 'Offre de vente non rétractable',

        },
        secondaryMarketVue: {
            login_btn: 'Inscrivez-vous maintenant',
            login_hint: 'Connectez-vous maintenant ou inscrivez-vous gratuitement, sélectionnez une offre, investissez et bénéficiez de distributions trimestrielles.'
        }
    },
    times: {
        months: 'Mois'
    },
    userEdit: {
        countries: {
            AT: 'Autriche',
            CH: 'Suisse',
            DE: 'Allemagne',
            FR: 'France',
            HU: 'Hongrie',
            IE: 'Irlande',
            LU: 'Luxembourg',
            NL: 'Pays-Bas'
        },
        description_change_name: 'Pour tout changement de nom, veuillez contacter notre service clientèle',
        legitimation_document_hint: `Si vous choisissez un passeport comme document d'identification, vous aurez besoin d'un <b> certificat d'enregistrement </b> pour ouvrir un dépôt. Cela peut retarder le processus d'ouverture d'un dépôt. Nous vous recommandons de vous identifier avec votre carte d'identité.`,
        link_change_name: 'https://exporo.fr/kontakt/',
        miles_and_more: 'N° de carte de service Miles & More',
        miles_tooltip: 'Nous offrons à tous les participants Miles & More qui investissent pour la première fois sur Exporo.fr, la possibilité d\'obtenir un bonus attractif allant jusqu\'à 15.000 Miles pour leur premier investissement.',
        months: {
            apr: 'Avril',
            aug: 'Août',
            dec: 'Décembre',
            feb: 'Février',
            jan: 'Janvier',
            jul: 'Juillet',
            jun: 'Juin',
            mar: 'Mars',
            may: 'Mai',
            nov: 'Novembre',
            oct: 'Octobre',
            sep: 'Septembre',
            year: 'Année'
        },
        not_us_taxed: 'Je ne suis pas assujetti à l\'impôt américain.',
        pass: 'Numéro de la carte d\'identité',
        pass_authority: 'Autorité de délivrance',
        pass_country_of_issue: 'Pays de délivrance',
        pass_created_at: 'Date de délivrance',
        pass_expired_at: 'Valable jusqu\'au',
        pep: 'Je suis une personne politiquement exposée (PPE).',
        pep_hint: `En tant que personne politiquement exposée (PPE), vous ne pouvez souscrire à certains produits qu'après un examen approfondi. Veuillez, s'il vous plaît, contacter notre support technique : <br><br>Support:<br>E-Mail : support@exporo.com <br> Téléphone : 01 81 22 41 68`,
        pep_tooltip: 'Une personne politiquement exposée (PPE) est une personne qui occupe ou a occupé une fonction publique importante de haut rang (par exemple, un homme politique, un ambassadeur, un juge de la Cour suprême) ou une personne de son environnement personnel immédiat (par exemple, un membre de sa famille ou un proche parent).',
        street_additional: 'Complément d\'adresse',
        street_and_no: 'Numéro et nom de rue',
        sub_title: 'Modifiez ici vos données personnelles.',
        success_text: 'Données mises à jour avec succès.',
        tax_id: 'Numéro d\'identification fiscale',
        tax_not_used_tooltip: 'La loi " FATCA " adoptée en 2010 exige que les ressortissants américains déclarent annuellement leurs comptes bancaires à l\'extérieur des États-Unis. En même temps, les institutions financières à l\'extérieur des États-Unis sont tenues d\'enregistrer les personnes américaines. En raison des exigences définies dans la FACTA, les clients assujettis à l\'impôt américain ne peuvent pas investir dans Exporo.',
        tax_not_used_short: 'En raison des exigences définies dans la FACTA, les clients assujettis à l\'impôt américain ne peuvent pas investir dans Exporo.',
        tax_nr: 'N° d\'identification fiscale',
        tax_tooltip_at: 'Le numéro d\'identification fiscale en Autriche est un numéro à neuf chiffres. Le numéro d\'identification fiscale se trouve en haut à gauche de l\'avis d\'imposition. S\'il n\'y a pas encore de cotisation d\'impôt sur le revenu, vous pouvez appeler l\'administration fiscale compétente. L\'administration fiscale devrait alors être en mesure de fournir des informations. Si elle n\'a pas de numéro d\'identification fiscale (NIF), vous pouvez en faire la demande directement auprès de l\'administration fiscale compétente.',
        tax_tooltip_de: 'Le numéro d\'identification fiscale est un numéro à onze chiffres que chaque citoyen enregistré à des fins fiscales en Allemagne reçoit. Le numéro d\'identification fiscale est également appelé "numéro d\'identification personnel", "numéro d\'identification" ou TIN (Tax Identification Number). En règle générale, vous trouverez votre numéro d\'identification fiscale dans votre avis d\'imposition ou sur votre certificat d\'impôt sous l\'un des noms ci-dessus. Si vous n\'avez pas d\'avis d\'imposition, vous pouvez obtenir votre numéro d\'identification fiscale auprès de l\'administration fiscale compétente.',
        tebmenu: {
            admin: 'Admin',
            data: 'Vos données',
            partner: 'Partenaire',
            wallet: 'Portefeuille numérique'
        },
        title: 'VOS DONNÉES PERSONNELLES',
        wallet_description: 'Le portefeuille  numérique vous permet d\'accéder et de gérer en temps réel vos investissements dans les projets du portfolio Exporo.',
        wallet_link: 'Ouvrir le portefeuille  numérique'
    }
};

export default fr;
