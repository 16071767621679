




















































import ActionCodeFile from './ActionCode';
import BankAccountDetails from './BankAccountDetails';
import ActionCodeUserDetails from './ActionCodeUserDetails';
import {Component, Prop} from 'vue-property-decorator';
import ExporoVue from '../ExporoVue';
import Events from '../../events';
import {debounce} from 'debounce';
import Input from '@/components/Input/Input.vue';
import IbanInput from '@/components/Iban/IbanInput.vue';
import RequestCallback from './../Rest/RequestCallback';
import {AxiosResponse} from 'axios';
import RestError from './../Rest/models/RestError';
import Checkbox from '@/components/Checkbox/Checkbox.vue';
import CheckoutValidator from '../Checkout/Validator/CheckoutValidator';
import Error from '@/components/Checkout/Validator/Error.vue';
import ActionCodeRequestApi from '../Rest/requests/ActionCodeRequestApi';
import InvestService from '../Checkout/services/InvestService';
import LoadingAnimation from '@/components/LoadingAnimation/LoadingAnimation.vue';

const DEBOUNCE_TIME: number = 1500;
const MIN_ACTION_CODE_LENGTH: number = 3;

type ActionCodeUserDetailsCallback = (p: ActionCodeUserDetails) => any;

@Component({
    components: {
        Checkbox,
        Error,
        Input,
        IbanInput,
        LoadingAnimation,
    },
})

export default class ActionCode extends ExporoVue implements RequestCallback {

    @Prop()
    private onChange!: ActionCodeUserDetailsCallback;

    @Prop()
    private product!: string;

    @Prop()
    private projectId!: number;

    @Prop()
    private step!: number;

    @Prop()
    private checkoutValidator!: CheckoutValidator;

    @Prop({default: MIN_ACTION_CODE_LENGTH})
    private minActionCodeLength!: number;

    @Prop()
    private investService!: InvestService;

    private showEvaluationSymbol: boolean = false;
    private evaluationSuccessful: boolean = false;
    private actionCode: string = '';

    private confirmed: boolean = false;
    private description: string = '';
    private actionCodeRequestApi: ActionCodeRequestApi;
    private debouncedLoader: () => void;
    private errorMessage: string = '';
    private files: ActionCodeFile[] = [];

    constructor() {
        super();

        this.actionCodeRequestApi = this.restApi.create(new ActionCodeRequestApi());
        this.debouncedLoader = debounce(this.endEvaluation, DEBOUNCE_TIME);
    }

    checkCode() {
        this.actionCodeRequestApi.check(this)
            .addParam('product', this.product)
            .addParam('projectId', String(this.projectId))
            .addParam('code', this.actionCode)
            .addParam('investSum', String(this.investService.getInvestSum()));
    }

    endEvaluation() {
        if (this.actionCode.length < this.minActionCodeLength) {
            this.showEvaluationSymbol = false;
            this.errorMessage = '';
            return;
        }
        this.checkCode();
    }

    evaluateInput(value) {
        if (this.actionCode.length >= this.minActionCodeLength) {
            this.showEvaluationSymbol = true;
            this.debouncedLoader();
        }

        this.evaluationSuccessful = false;
        this.triggerOnChange();
    }

    triggerOnChange() {
        if (null !== this.onChange) {
            this.onChange({
                code: this.actionCode,
                confirmed: this.confirmed,
                reuseIban: false
            });
        }
    }

    onSuccess(response: AxiosResponse): void {
        if (undefined !== response.data.code && response.data.code !== this.actionCode) {
            return;
        }

        this.files = [];
        this.showEvaluationSymbol = false;
        this.evaluationSuccessful = response.data.valid;

        if (!response.data.valid) {
            const transKey = 'checkout.action_code.error_messages.' + response.data.message;
            if (response.data.value !== null) {
                this.errorMessage = this.trans.get(transKey, [response.data.value]);
            } else {
                this.errorMessage = this.trans.get(transKey);
            }
            return;
        }

        this.errorMessage = '';
        this.description = response.data.actionCode.description;

        if (response.data.actionCode.conditionsFile) {
            this.files.push(response.data.actionCode.conditionsFile);
        }
    }

    onFailure(error: RestError): void {
        this.evaluationSuccessful = false;
        this.errorMessage = this.trans.get('checkout.action_code.error_message');
    }
}
