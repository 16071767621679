






























































































































































































































import Accordion from '../../Accordion/Accordion.vue';
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import IbanService from '../../Iban/services/IbanService';
import IbanInput from '../../Iban/IbanInput.vue';
import ForceEmailCheckbox from '@/components/Checkout/ForceEmailCheckbox/ForceEmailCheckbox.vue';
import InvestService from '../services/InvestService';
import Error from '@/components/Checkout/Validator/Error.vue';
import CheckoutValidator from '../Validator/CheckoutValidator';
import Checkbox from '@/components/Checkbox/Checkbox.vue';
import Select from '@/components/Select/Select.vue';
import Input from '@/components/Input/Input.vue';
import InvestmentRequestPart from '../models/InvestmentRequestPart';
import StoreInvestmentRequestService from '../services/StoreInvestmentRequestService';
import ActionCode from '@/components/ActionCode/ActionCode.vue';
import ActionCodeUserDetails from '@/components/ActionCode/ActionCodeUserDetails';
import ExporoVue from '../../ExporoVue';

@Component({
    components: {
        Accordion,
        ActionCode,
        ForceEmailCheckbox,
        IbanInput,
        Error,
        Checkbox,
        Select,
        Input,
    },
})
class InvestmentVermAnlG extends ExporoVue implements InvestmentRequestPart {

    private MIN_INVESTMENT_SUM = 2000;

    private payloadPart: any = {
        investsum: this.MIN_INVESTMENT_SUM,
        investtype: '',
        bank_account_data_should_be_saved: 1,
        dsgvo_secupay_hint: 1,
        f_use_bank_account_for_iban: 1,
        f_use_bank_account_for_kto: 0,
        invest_iban: '',
        invest_bic: '',
        debitMandate: false,
        smallInvestor: false,
    };

    public TITLE = this.trans.get('checkout.VermAnlG.investmentVermAnlG.title');
    @Prop()
    private investService!: InvestService;

    private accountformat = 'ibanbic';
    @Prop()
    private checkoutValidator!: CheckoutValidator;
    @Prop()
    private bankAccount!: any;
    @Prop()
    private shouldHide!: boolean;
    @Prop()
    private switchStep!: () => void;
    @Prop()
    private step!: number;
    @Prop()
    private ibanDecrypted!: string;
    private f_use_bank_account_for_iban: string = '0';
    private f_use_bank_account_for_kto: string = '0';
    private use_bank_account: string = '0';
    private bank_account_data_should_be_saved: number = 0;

    private investsum: number | null = 2000;
    private ibanService: IbanService;
    private iban: string = '';
    private bic: string = '';
    private invest_iban: string = '';
    private invest_bic: string = '';

    @Prop()
    private storeInvestmentRequestService!: StoreInvestmentRequestService;
    @Prop()
    private investmentProduct!: models.InvestmentProduct;
    @Prop()
    private isPrivatePlacement!: boolean;
    @Prop()
    private isPreFinancing!: boolean;
  private investtype = this.privatePlacement || this.isPreFinancing ? 'prepay' : 'debit';

    onChangeIbanBic(ibanBic: { iban: string, bic: string }) {
        this.payloadPart.invest_iban = ibanBic.iban.toString().replace(/\s/g, '');
        this.payloadPart.invest_bic = ibanBic.bic.toString().replace(/\s/g, '');
    }

    @Watch('invest_iban')
    onIbanChanged(val: string, oldVal: string) {
        this.investService.updateFomData('invest_iban', val);
        this.payloadPart.invest_iban = val;
        this.payloadPart.action_code_iban = this.payloadPart.invest_iban;
    }

    @Watch('invest_bic')
    onBicChanged(val: string, oldVal: string) {
        this.investService.updateFomData('invest_bic', val);
        this.payloadPart.invest_bic = val;
        this.payloadPart.action_code_bic = this.payloadPart.invest_bic;
    }

    onChangeSum(val: any) {
      this.investsum = val;
    }

    onChangeInput(name: string) {

        const checkbox: any = document.getElementById(name);
        if (checkbox) {
            this.investService.updateFomData(name, checkbox.checked);
            this.payloadPart[name] = checkbox.checked;
        }
    }

    @Watch('investsum')
    onInvestsumChanged() {
      this.investService.setInvestSum(this.investsum);
      this.payloadPart.investsum = this.investsum;
    }

    @Watch('investtype')
    onInvestTypeChanged() {
        this.investService.updateFomData('investtype', this.investtype);
        this.investService.setInvestType(this.investtype);
        this.setUseBankAccount();
        this.payloadPart.investtype = this.investtype;
    }

    @Watch('f_use_bank_account_for_iban')
    onUseBankAccountForIbanChange() {
        this.investService.updateFomData('f_use_bank_account_for_iban', this.f_use_bank_account_for_iban);
        this.payloadPart.f_use_bank_account_for_iban = this.f_use_bank_account_for_iban;
    }

    @Watch('bank_account_data_should_be_saved')
    onUseBankAccountChange() {
        this.investService.updateFomData('bank_account_data_should_be_saved', this.bank_account_data_should_be_saved);
        this.payloadPart.use_bank_account = this.f_use_bank_account_for_iban;
    }

    @Watch('accountformat')
    onAccountFormatChanged() {

        if (this.isBankAccountValid(this.bankAccount, ('accountnumber' === this.accountformat ? 'account' : 'iban'))) {
            this.use_bank_account = '1';
        } else {
            this.use_bank_account = '0';
        }

        this.setUseBankAccount();
        this.investService.updateFomData('accountformat', this.accountformat);
        this.payloadPart.accountformat = this.accountformat;
    }

    @Watch('use_bank_account')
    onUseBankAccountChanged() {

        this.setUseBankAccount();
    }

    isBankAccountValid(bankAccount: any, type: string): boolean {

        let isValid: boolean = true;

        if (!bankAccount) {
            isValid = false;
        } else {
            if ('iban' === type) {
                isValid = bankAccount.iban && bankAccount.bic;
            } else {
                isValid = bankAccount.account_number && bankAccount.bank_code;
            }
        }

        return isValid;
    }

    onActionCodeChanged(values: ActionCodeUserDetails) {
        this.payloadPart.action_code = values.code;
        this.payloadPart.action_code_confirm = values.confirmed;
        this.payloadPart.action_code_reuse_iban = values.reuseIban;
        this.payloadPart.action_code_iban = this.payloadPart.invest_iban;
        this.payloadPart.action_code_bic = this.payloadPart.invest_bic;
    }

    mounted() {
      if (this.isPreFinancing) {
        this.investtype = 'prepay';
      }
        this.setUseBankAccount();
        this.investService.updateFomData('accountformat', this.accountformat);
        this.payloadPart.accountformat = this.accountformat;
        this.payloadPart.investtype = this.investtype;

        if (this.isBankAccountValid(this.bankAccount, ('accountnumber' === this.accountformat ? 'account' : 'iban'))) {
            this.use_bank_account = '1';
        }
    }

    constructor() {
        super();
        this.ibanService = new IbanService();
        this.storeInvestmentRequestService.registerRequestPart(this);
    }

    private setUseBankAccount() {

        if ('1' === this.use_bank_account) {
            this.f_use_bank_account_for_iban = '1';
            this.f_use_bank_account_for_kto = '1';
        } else {
            if ('accountnumber' === this.accountformat) {
                this.f_use_bank_account_for_iban = '1';
                this.f_use_bank_account_for_kto = '0';
            } else {
                this.f_use_bank_account_for_iban = '0';
                this.f_use_bank_account_for_kto = '1';
            }
        }

        this.payloadPart.f_use_bank_account_for_iban = this.f_use_bank_account_for_iban;
        this.payloadPart.f_use_bank_account_for_kto = this.f_use_bank_account_for_kto;

        if (this.isPreFinancing) {
          this.f_use_bank_account_for_iban = '0';
        }
    }

    getRequestPart(): object {

        return this.payloadPart;
    }

    get privatePlacement(): boolean {
        return this.isPrivatePlacement;
    }
}

export default InvestmentVermAnlG;
